<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-form-group :label="$t('status')">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <parameter-selectbox
                        code="document_request_statuses"
                        v-model="status"
                        :validateError="errors[0]"></parameter-selectbox>
                </ValidationProvider>
            </b-form-group>
        </ValidationObserver>
        <div class="row mb-5">
            <div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4">
                <b-button variant="primary" @click="updateForm">{{ $t('save') }}</b-button>
            </div>
        </div>

    </div>
</template>

<script>
// Components

// Services

// Other
import {ValidationObserver, ValidationProvider} from "vee-validate"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import DocumentRequestService from "../../../services/DocumentRequestService";

export default {
    components: {

        ValidationProvider,
        ValidationObserver,

        ParameterSelectbox
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
        currentStatus: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            status: null
        }
    },
    watch: {
        id: function (val) {

        },
        currentStatus: function (val) {
            this.status = val
        }
    },
    created() {

    },
    methods: {
        async updateForm() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let formData = {
                    status: this.status
                }

                DocumentRequestService.updateStatus(this.id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('hide')
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.status) {
                            this.$refs.formValidate.errors.status.push(e.data.errors.status[0]);
                        }
                    }
                })
            }
        }
    }
}
</script>
